import React from "react"
import styled, { css } from "styled-components"
import { getObjectStyles, merge } from "./utilities/themify"

const navStyles = css`
  ${props => {
    const mergedThemes = props.theme.nav
      ? merge(StyledNav.defaultProps.theme.nav.styles, props.theme.nav.styles)
      : StyledNav.defaultProps.theme.nav.styles

    return getObjectStyles(mergedThemes)
  }}

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

const StyledNav = styled.nav`
  ${navStyles}
`

export default function Nav({ children, className }) {
  return <StyledNav className={className}>{children}</StyledNav>
}

StyledNav.defaultProps = {
  theme: {
    nav: {
      styles: {
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: "0",

        marginBottom: "0",
        // stack: "0",
        //

        listStyle: "none",

        a: {
          display: "block",

          padding: "0.5rem 1rem",
          // inset: "0.5rem 1rem",
          //

          textDecoration: "none",
          transition:
            "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",

          "&:disabled, &.disabled": {
            pointerEvents: "none",

            color: "#6c757d",
            cursor: "default",
          },
        },
      },
    },
  },
}
