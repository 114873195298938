export function translateTheme(obj) {
  const flatten = (obj, prefix = "", res = {}) =>
    Object.entries(obj).reduce((r, [key, val]) => {
      const k = `${prefix}${key}`
      if (typeof val === "object") {
        flatten(val, `${k}.`, r)
      } else {
        res[k] = val
      }
      return r
    }, res)

  const flattenedTheme = flatten(obj)

  const dictionary = {
    btn: "button",
    inset: "padding",
    inline: "margin-right",
    link: "a",
    stack: "margin-bottom",
  }

  const translate = src => {
    let target = {}
    for (const [key, val] of Object.entries(src)) {
      let newKey = key
      let newVal = typeof val === "object" ? translate(val) : val
      for (const [dKey, dVal] of Object.entries(dictionary)) {
        if (key === dKey) newKey = dVal
      }
      for (const [tKey, tVal] of Object.entries(flattenedTheme)) {
        if (val === tKey) newVal = tVal
      }
      target[newKey] = newVal
    }
    return target
  }

  return translate(obj)
}

export function getObjectStyles(obj) {
  function camelToKebab(str) {
    return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase()
  }
  let styleString = ""
  for (const [key, val] of Object.entries(obj)) {
    styleString +=
      typeof val === "string"
        ? `${camelToKebab(key)}: ${val}; `
        : typeof val === "object"
        ? `${camelToKebab(key)} { ${getObjectStyles(val)}} `
        : ""
  }

  return styleString
}

export function merge(...args) {
  let target = {}
  let merger = obj => {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === "[object Object]") {
          target[prop] = merge(target[prop], obj[prop])
        } else {
          target[prop] = obj[prop]
        }
      }
    }
  }
  for (let i = 0; i < args.length; i++) {
    merger(args[i])
  }

  return target
}
