/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"

import "../css/bootstrap-reboot.min.css"
import "../css/layout.css"

import styled, { ThemeProvider } from "styled-components"

import { translateTheme } from "./themableUI/utilities/themify"

import { nightTheme, defaultTheme } from "../themes/themes"

import GlobalStyle from "../themes/globalStyle"

import Header from "./header"
import Logo from "./logo"
import Nav from "./themableUI/nav"

import Container from "./themableUI/container"

import ThemeButton from "./theme-button"

const PageNav = styled(Nav)`
  margin-left: auto;
  flex-wrap: nowrap;
`

const StyledContentWrapper = styled(Container)`
  min-height: 100vh;
  padding-top: ${props => props.theme.headerHeight};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`

const StyledFooter = styled.footer`
  min-height: ${props => props.theme.footerHeight || "  30px"};
  display: flex;
  align-items: center;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isNightMode, toggleTheme] = useState(false)
  const [activeLink, setLink] = useState("")

  const passFunc = { setLink: str => setLink(str) }

  return (
    <ThemeProvider
      theme={
        isNightMode
          ? translateTheme({ ...nightTheme, ...passFunc })
          : translateTheme({ ...defaultTheme, ...passFunc })
      }
    >
      <GlobalStyle />

      <div className="layout">
        <Header
          dividerIsOpen={false}
          siteTitle={
            data.site.siteMetadata ? data.site.siteMetadata.title : `Title`
          }
        >
          <Logo />
          <PageNav>
            <Link activeClassName="is-active" partiallyActive={true} to="/work">
              work
            </Link>
            <Link
              className={activeLink === "about" ? "is-active" : ""}
              to="/#about"
            >
              about
            </Link>
            <Link
              className={activeLink === "contact" ? "is-active" : ""}
              to="/#contact"
            >
              contact
            </Link>
          </PageNav>
        </Header>

        <StyledContentWrapper>
          <main>{children}</main>

          <StyledFooter>
            <span>© {new Date().getFullYear()}, Xooldra Digital</span>
            <ThemeButton
              isNightMode={isNightMode}
              onClick={() => toggleTheme(!isNightMode)}
            ></ThemeButton>
          </StyledFooter>
        </StyledContentWrapper>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
