// themedProp allows any value in styled component to be defaulted by the theme. If the prop called propName is not set, themedProp searches the theme for the same prop. It first searches for theme.componentName.propName then continues using the conditionals below. This approach allows props to be incorporated into a theme whilst not requring it. A fallback should still be provided when themedProp returns '' (falsey)

export default function themedProp(
  themeObject,
  styleProp,
  fallback,
  searchArray,
  aliasArray,
  testTheme = true
) {
  if (searchArray) {
    for (const item of searchArray) {
      if (themeObject[item]) {
        if (themeObject[item][styleProp]) {
          return themeObject[item][styleProp]
        }
        if (aliasArray) {
          for (const alias of aliasArray) {
            if (themeObject[item][alias]) {
              return themeObject[item][alias]
            }
          }
        }
      }
    }
    if (!testTheme) {
      if (fallback) {
        return fallback
      } else {
        return ``
      }
    }
  }

  if (themeObject[styleProp]) {
    return themeObject[styleProp]
  }
  if (aliasArray) {
    for (const alias of aliasArray) {
      if (themeObject[alias]) {
        return themeObject[alias]
      }
    }
  }
  if (fallback) {
    return fallback
  }
  return ``
}
