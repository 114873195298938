/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import styled from "styled-components"

const StyledThemeButton = styled.button`
  display: inline-block;
  margin-left: auto;
  padding: 0;
  position: relative;
  border: none;
  background-color: transparent;
  width: 30px;
  overflow-x: hidden;
  div {
    > * {
      display: inline;
    }

    width: 60px;
    display: flex;

    ${props =>
      props.isNightMode
        ? "transform: translateX(-15px);"
        : "transform: translateX(0px);"}
    transition: transform 0.5s linear;
  }
`

const ThemeButton = ({ onClick, isNightMode }) => {
  return (
    <StyledThemeButton isNightMode={isNightMode} onClick={onClick}>
      <span className="screen-reader-text">change theme</span>
      <div>
        <StaticImage
          src="../images/luna.png"
          width={30}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Xooldra Digital Logo"
        />
        <StaticImage
          src="../images/sol.png"
          width={30}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Xooldra Digital Logo"
        />
      </div>
    </StyledThemeButton>
  )
}

export default ThemeButton
