import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

import { getObjectStyles } from "./themableUI//utilities/themify"

import Container from "./themableUI/container"

const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;

  height: ${props => props.theme.headerHeight || "50px"};

  ${props =>
    props.theme.header ? getObjectStyles(props.theme.header.styles) : ""}
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  width: 100%;
  > * a {
    display: flex;
  }
`

const Header = ({ children }) => (
  <StyledHeader>
    <StyledContainer>{children}</StyledContainer>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
