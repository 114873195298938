import React from "react"
import styled from "styled-components"
import { getObjectStyles } from "./utilities/themify"

import themedProp from "./utilities/themedProp"

const nameInTheme = "container"

const StyledContainer = styled.div`
  box-sizing: border-box;

  width: 100%;

  padding-right: ${props =>
    `var(--gutter-x, calc(` +
    themedProp(props.theme, "gutter", "1.5rem", ["grid", nameInTheme]) +
    ` / 2))`};

  padding-left: ${props =>
    `var(--gutter-x, calc(` +
    themedProp(props.theme, "gutter", "1.5rem", ["grid", nameInTheme]) +
    ` / 2))`};

  margin-right: auto;
  margin-left: auto;

  ${props => {
    if (props.isFluid || themedProp(props.theme, "isFluid", "", [nameInTheme]))
      return

    const breakpoints = themedProp(props.theme, "breakpoints", "", [
      nameInTheme,
    ]) || {
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
      xxl: "1400px",
    }

    const maxWidths = themedProp(props.theme, "maxWidths", "", [
      nameInTheme,
    ]) || {
      sm: "540px",
      md: "720px",
      lg: "960px",
      xl: "1140px",
      xxl: "1320px",
    }

    let isFluidBelow =
      props.isFluidBelow ||
      themedProp(props.theme, "isFluidBelow", "", [nameInTheme]) ||
      false
    let skip = isFluidBelow ? true : false
    let mediaQueryString = ""

    for (const index in maxWidths) {
      const breakpoint = breakpoints[index]
      if (index === isFluidBelow) skip = false
      if (!skip) {
        mediaQueryString += `@media (min-width: ${breakpoint}) {
        max-width: ${maxWidths[index]};
      };
      `
      }
    }
    return mediaQueryString
  }}

  ${props => {
    return props.theme.container && props.theme.container.styles
      ? getObjectStyles(props.theme.container.styles)
      : ""
  }}
`

export default function Container({
  style,
  children,
  isFluid,
  isFluidBelow,
  className,
}) {
  return (
    <StyledContainer
      className={className}
      style={style}
      isFluid={isFluid}
      isFluidBelow={isFluidBelow}
    >
      {children}
    </StyledContainer>
  )
}
